import React, { Component } from 'react'

export default class LoginDeviantTrackerFolder extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	password: "",
	      	filename: "",
	      	error: false,
	    };
	}

	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }



	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    if(this.props.currentStep === 0){
	    	const filename = this.state.filename.toLowerCase()
	    	if(this.props.filenames.includes(filename)){
	    		this.props.openEditor(filename)
	    		this.setState({ error: false });
	    	}else{
	    		this.setState({ error: "Please enter a valid file name." });
	    	}
	    }else{
	    	const password = this.state.password
	    	if(this.props.password ===password){
	    		this.props.openEditor(password)
	    		this.setState({ error: false });
	    	}else{
	    		this.setState({ error: "Invalid Password." });
	    	}

	    	//Invalid Password
	    }


	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

        return (<div className="login-box-editor rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
                	
                		 {(this.props.currentStep === 0 ) && (<h1> Please enter the name of the file you wish to upload to the editor, including the extension. </h1> )}
              			 {(this.props.currentStep === 1 ) && (<h1> Please enter sharing permissions password. </h1> )}
			        	
			        	

			        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
			        		<div className="">
			        			
	              				<div className=" mb-3">

	              				{(this.props.currentStep === 0 ) && (
	              					<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
		              onChange={this.handleChange} type="text"  id="filename" placeholder="filename.jpg" onKeyPress={this.handleKeyPress} autoComplete="on" />

	              				)}

	              				{(this.props.currentStep === 1 ) && (
	              					<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
		              onChange={this.handleChange} type="password"  id="password" placeholder="password" onKeyPress={this.handleKeyPress} autoComplete="on" />
	              				)}
				        			
		              				<p className="error mt-2">{this.state.error && this.state.error} &nbsp;</p>
	              				</div>
			        		</div>
			        		<div className="flex items-center justify-between">
						      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" >
						        ENTER
						      </button>
						     
						    </div>
			        	</form>

			        </div>

)
    }




}
