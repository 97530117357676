import React, { Component } from 'react'

export default class ManualDecoderDeviantTrackerView extends Component {

	constructor(props) {
	    super(props);
	    
	    this.state = {
	    	alphabet:{a:"",b:"",c:"",d:"",e:"",f:"",g:"",h:"",i:"",j:"",k:"",l:"",m:"",n:"",o:"",p:"",q:"",r:"",s:"",t:"",u:"",v:"",w:"",x:"",y:"",z:"", " ":"/"},
	    	decryptedText:[],
	    	inputText:""
	    }

	    //console.log(this.state.alphabet)
	}


	handleChangeAlphabet = event => {
        
		let alphabet = this.state.alphabet
		alphabet[event.target.id] = event.target.value


		this.setState({alphabet:alphabet});


		this.updateDecryptedText(this.state.inputText)
        /*this.setState({
          [event.target.id]: event.target.value
        });*/
     }


     handleChangeInput = event => {
        
		//console.log("handleChangeInput")
		const inputText = event.target.value.toLowerCase();
		
		this.setState({inputText:inputText});
		this.updateDecryptedText(inputText)
		
		
		
     }

     updateDecryptedText(input){
     	let decryptedText = [];
		for (var i = 0; i < input.length; i++) {
			const char = input.charAt(i)
			decryptedText.push(this.state.alphabet[char])
		    
		}

		//console.log(decryptedText);
		this.setState({decryptedText:decryptedText});
     }


     handleShift = event => {
        
       // console.log("handleShift");
        
        //console.log(this.state.alphabet);

       // console.log(this.state.inputText);

       // console.log(this.state.decryptedText);

        /*console.log(this.decrypt(this.state.shiftTextInput, (this.state.shiftValue)));

        this.setState({decryptedText:this.decrypt(this.state.shiftTextInput, (this.state.shiftValue))});*/

        //
     }

    render() {
    	
    	const alphabet = Object.keys(this.state.alphabet).map(key => 
    				<div key={key} className="cipher-letter">
						<span>{key}:</span>
						<input type="text" maxLength="1" className="w-6 h-8" id={key} value={this.state.alphabet[key]}  onChange={this.handleChangeAlphabet}/>
					</div>
        		)

        const decryptedText = this.state.decryptedText.map((char, index) => 
        	<div key={index}>
        		<div className={"cipher-char " + (char !== "/" ? 'border-b-2 border-grey' : '') }>{char}</div>
        	</div>

        )

        return (<div className="decoder-container" > 
        	<h4>Tools</h4>
        	<h2>{this.props.icon.name}</h2>
            <div className="relative">
                <div className="gallery-viewer__back" onClick={this.props.goBack}>
                    <span className="gallery-viewer__back-arrow">&lt;</span>
                    <span className="gallery-viewer__back-text">GO BACK</span>
                </div>
            </div>
            <p>Enter the encrypted message into the blank box. To substitute an encrypted letter with a plaintext letter, enter the plaintext letter into the corresponding box.
For example: if you want to replace all instances of the encrypted letter ‘D’ with the plaintext letter ‘A’, enter ‘A’ into the blank box next to ‘D’.</p>

			
        	
        	<div className="manual-decoder-content ">
                <div className="flex flex-wrap p-8 pt-0 justify-center items-center decryptedText-container mobile">
                    {decryptedText}
                </div>
        		<input type="text" value={this.state.inputText} id="inputText"  onChange={this.handleChangeInput} placeholder="Enter Text here"/>
				<div className="alphabet-container">
					{alphabet}
				</div>

				{/* <div className="cta-contaniner mt-4 ">
                    <div className="flex">
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleShift}>Decrypt</button>
                    </div>
                </div>*/}  

                <div className="flex flex-wrap p-8 pt-0 justify-center items-center decryptedText-container desktop">
	        		{decryptedText}
        		</div>

			</div>

			


        	</div>)
    }




}
