import React, { Component } from 'react'

export default class ClassSchedule extends Component{
  render (){
    return (
    <div>

    	<table className="classSchedule">
  <thead>
	  <tr>
	    <th>Class</th>
	    <th>Title</th>
	    <th>Description</th>
	    <th>Professor</th>
	    <th>Day/Time</th>
	    <th>Room</th>
	  </tr>
  </thead>
  <tbody>
	  <tr>
	    <td>BIO 485</td>
	    <td>Invasive Species and Their Effects</td>
	    <td>Learn how invasive species have rapidly changed their habitats, what can be done about them, and why such species were introduced in the first place.</td>
	    <td>F. Kowalski</td>
	    <td>TH 5:00-8:40</td>
	    <td>SB 310</td>
	  </tr>
	  <tr>
	    <td>ZOO 450</td>
	    <td>Wildlife Management and Ecology</td>
	    <td>Learn how organizations worldwide are fighting to protect endangered species and their environments from threats both manmade and natural.</td>
	    <td>D. Mitchell</td>
	    <td>MW 5:00-8:40</td>
	    <td>SB 302</td>
	  </tr>
	  <tr>
	    <td>ZOO 500</td>
	    <td>Capstone Project - Individual Study</td>
	    <td>Topic must be approved by professor.</td>
	    <td>A. Burnham</td>
	    <td>N/A</td>
	    <td>N/A</td>
	  </tr>
  </tbody>
</table>


	</div>
  );
  }
};