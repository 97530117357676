import React, { Component } from 'react'

export default class AcademicResources extends Component{
  render (){
    return (
    <div>
<h3 className="mb-2">Academic Advising</h3>
<p>Unsure of what class to take next for your major? Or even what your major will be? Maybe you’re considering grad school. No matter the issue, our academic advisors can help you get on the right path and provide tutoring for any difficult classes.</p>
<h3 className="mb-2">Writing Support</h3>
<p>Need help on a difficult paper? Formatting issue got you down? Make an appointment at the Writing Center, located in the library, for individualized help with school projects. Spaces are extremely limited towards the end of the semester.</p>
<h3 className="mb-2">Stress Management</h3>
<p>Stressful semester? Whitlock’s Counseling Center has excellent stress management services, including counselors and student-led study groups. We also provide discounts for local stress-relieving businesses, such as Pet-A-Puppy, where dogs can help you de-stress, and the Break Room!, where you can take a baseball bat to your problems.</p>	

	</div>
  );
  }
};