import React, { Component } from 'react'
import store from 'store';
import Div100vh from 'react-div-100vh'
import DesktopView from './views/DesktopView'
import LoginView from './views/LoginView'
import { users } from './datas/users.json';
import { icons } from './datas/season6/icons.json';
import { getUser } from './utils.js';
class Desktop extends Component {

	constructor(props) {
	    super(props);

	    var currentEpisode = 0
	    const password = store.get("password");
	    if (password == null || password === false){
	    	//console.log("notloggedin")
	    }else{
	    	var user = getUser(password, users);
	    	//console.log(user)
	    	if (user != null){
	    		currentEpisode = user.episode
			}
	    }



	    this.state = {
	     
	      loginError: false,
	      currentEpisode: currentEpisode,
	    }

	    
	}


	handleSubmit(password){
		//console.log(password)
		//console.log("LALALA")


		var user = getUser(password, users);
		//console.log(user)
		this.setState({ loginError: false });

		if (user == null){
		    // wrong PW
		    this.setState({ loginError: true });
		}else{
			// redirect to correct season
			//console.log(user)
			store.set('password', user.pw);
			this.setState({ currentEpisode: user.episode });

			
		}
	}

	handleLogout(){
		this.setState({ currentEpisode: 0 });
	}


	render() {
		
		var currentEpisode = this.state.currentEpisode



		return (<div>
		  {currentEpisode > 0 && (
				 <DesktopView className="season5" icons={icons} currentEpisode={currentEpisode} history={this.props.history} handleLogout={this.handleLogout.bind(this)}  />
			)}



			{currentEpisode <= 0 && (
        				<Div100vh  dontresize="true" className="season5" >
        					<LoginView users={users} history={this.props.history} error={this.state.loginError}   handleSubmit={this.handleSubmit.bind(this)}/>
        				</Div100vh>
        			)}


		 
		  

		  </div>

		)

	}
}

export default Desktop