
import React, { Component } from 'react'

export default class NewsAndEvents extends Component{
  render (){
    return (
    <div>
<p><b>On-Campus Incident Warning:</b> Due to a recent increase in daytime sightings of raccoons around campus, and reports of their strange behavior, we must encourage everyone to avoid approaching wild animals. Please report any raccoon sightings and other related incidents to Campus Police.</p>
	</div>
  );
  }
};