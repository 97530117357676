import React, { Component } from 'react'
import { universityportal } from '../datas/season6/universityportal.json';
import store from 'store';

export default class LoginUniversityPortal extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password: "",
	      username: "",
	      error: false,
	    };
	}

	

  	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    const password = this.state.password;
	    const username = this.state.username;
	    this.setState({ error: false });
	    
	    /*
	    if(password.toLowerCase() !== devianttracker.password.toLowerCase() || username.toLowerCase() !== devianttracker.username){
	    	return this.setState({ error: true });
	    }else{
	    	store.set('dtpassword', password);
	    	this.props.validateLogin()
	    	//return this.setState({ isUnlocked: true });
	    }*/

	    var i;
		for (i = 0; i < universityportal.users.length; i++) {
			if(password.toLowerCase() !== universityportal.users[i].password || username.toLowerCase() !== universityportal.users[i].username){
		    	
		    	this.setState({'error':true})
		    }

			if(password.toLowerCase() === universityportal.users[i].password &&  username.toLowerCase() === universityportal.users[i].username){
				
				store.set('universitypassword', password);
				this.props.validateLogin({'username':universityportal.users[i].username,'index':i})
				
			}
		}
		//return this.setState({ isUnlocked: isUnlocked, currentUser:currentUser, error: error });

	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

        return (<div className="login-dt h-full w-full flex justify-center items-center z-10"  onMouseDown={this.props.tapBack}>



                	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
                	<div className="mr-2 "><img onMouseDown={this.props.tapBack} className=" close-icon-modal close-dtlogin"  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s6/dt-close-button.svg"/></div>
		        		<img src="https://cdn.huntakiller.com/huntakiller/s6/whitlock-university.svg" alt="logo" />
			        	<h2> Welcome to the Whitlock University Student & Faculty Portal</h2>
			        	

			        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
			        		<div className="">
			        			<div className=" mb-3">
			        				<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.username}
	              onChange={this.handleChange} type="text" autoFocus id="username" placeholder="Username" onKeyPress={this.handleKeyPress}/>
	             					
	              				</div>
	              				<div className=" mb-3">
				        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
		              onChange={this.handleChange} type="password"  id="password" placeholder="Password" onKeyPress={this.handleKeyPress} autoComplete="on" />
		              				<p className="error mt-2">{this.state.error && "Oops! Something is wrong."} &nbsp;</p>
	              				</div>
			        		</div>
			        		<div className="flex items-center justify-between">
						      <button className="bg-grey mx-8  w-full text-blue font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" >
						        ENTER
						      </button>
						     
						    </div>
			        	</form>

			        </div>




                </div>)
    }
}
