import React, { Component } from 'react'
import Draggable from 'react-draggable';
import {isMobile, isIOS13} from 'react-device-detect';
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import ImageViewer from './ImageViewer'
import DesktopIcon from './DesktopIcon'
import '../css/scrollbar.css'
import NoteToSelf  from '../rtf/notetoself';
import Assignment  from '../rtf/assignment';


const components = {
    "Note To Self.txt": NoteToSelf,
    "Assignment.txt": Assignment

};



export default class Modal extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props.modal)
        this.updateDimensions = this.updateDimensions.bind(this)
        this.state = {
            
            previousPosition: {
              x: 200, y: 200
            },
            modal: this.props.modal,
            
          };

    }


    onStop = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y

    };

    onStart = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y
       this.props.updateZindex(this.state.modal)
    };

    handleDrag = (e, position) => {
        const {x, y} = position;
        const modal = this.state.modal
        modal.left = x
        modal.top = y
        this.setState({modal: modal});
        this.setState({previousPosition: {x, y}});

    };


    componentDidMount() {
        this.updateDimensions()
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            //this.setState({previousPosition: this.state.controlledPosition});
            //this.setState({controlledPosition: {x:0, y:0}});
            const modal = this.state.modal
            modal.left = 0
            modal.top = 0
             this.setState({modal: modal, previousPosition: this.state.controlledPosition});
        }else{
            //const modal = this.state.modal
            //modal.left = (window.innerWidth - parseInt(this.props.modal.width))/2
            //modal.top = (window.innerHeight - parseInt(this.props.modal.height))/2
            //this.setState({modal: modal});
            //this.setState({previousPosition: {x:(window.innerWidth -parseInt( this.props.modal.width))/2, y:(window.innerHeight - parseInt(this.props.modal.height))/2}});

        }

        window.addEventListener("resize", this.updateDimensions);
    };

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };

    updateDimensions() {
        //console.log("resize")
        const modal = this.state.modal
        var newIcons = modal.icons
        //console.log(newIcons)
         if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            const modal = this.state.modal
            this.setState({previousPosition: {x: modal.left , y: modal.top}});
            //this.setState({controlledPosition: {x:0, y:0}});
            
            modal.left = 0
            modal.top = 0
            this.setState({modal: modal});

            let i = 0
            let top = 10
            let left = 10

            newIcons.forEach(icon => {
              if (i % 3 === 0) {
                if (i !== 0) {
                  top += 124
                }
                left = 10
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left
              i++
            })

        }else{
            this.setState({controlledPosition: this.state.previousPosition});
            let i = 0
            let top = 10
            let left = 10
            newIcons.forEach(icon => {
              if (i % 6 === 0) {
                if (i !== 0) {
                  top += 120
                }
                left = 10
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left
              i++
            })
            
            
        }

        modal.icons = newIcons
        this.setState({modal:modal});
        

    }
    closeModal = (e) => {
        this.props.closeModal(this.props.modal)
    };
   
   
    render() {
        const dragHandlers = { onStop: this.onStop, onDrag:this.handleDrag, onStart:this.onStart};

    	//const modal = this.stat.modal
        const {modal} = this.state;

        let controlledPosition = {x: modal.left, y: modal.top}

        var modalStyle;
        if(isMobile || isIOS13){
            modalStyle = {
                zIndex:modal.z,
                width:"100%",
                height:"100%",
            }

        }else{
            modalStyle = {
                zIndex:modal.z,
                width:modal.width, 
                height:modal.height,
        	}
        }
        const iconviews = modal.icons.map((icon, index) => {
            if(icon.episode <= this.props.currentEpisode){
                return (
                  <DesktopIcon icon={icon} key={index}  iconSingleClick={this.props.iconSingleClick}/> 
                )
            }else return false
            
          })

        const clearStyle = {
          clear: 'both'
        };

        const Component = components[modal.name]
        
        return (
            <Draggable position={controlledPosition}  {...dragHandlers}  disabled={(isMobile || isIOS13) ? true : false}>
    			<div className="modal" style= {modalStyle} id={`modal-${modal.id}`}>
                <Div100vh   dontresize="true" className="modalDiv" style={{maxHeight: isMobile ? '' : '100%'}}>
                    <div className="modalcontainer">
                        <div className="header">
                            <h2>{modal.name}</h2><
                            button className="close" data-modal-id={`modal-${modal.id}`} onClick={this.closeModal}/>
                        </div>
                        
                        
                        {modal.type === "folder" && (<PerfectScrollbar><div className="content-file folder-content">{iconviews}<div style={clearStyle}></div></div></PerfectScrollbar>)}
                        {modal.type === "image" && (
                                <ImageViewer initialWidthPercent="100" img_url={modal.image_url} img_name={modal.name}/>

                        )}


                        {modal.type === "text" && (
                               

                               <PerfectScrollbar><Component currentEpisode={this.props.currentEpisode}/></PerfectScrollbar>
                               

                        )}
                      
                        
        			</div>
                </Div100vh>
                </div>
            </Draggable>
        )
    }
}
