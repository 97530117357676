import React, { Component } from 'react'
import ImageViewerDeviantTracker from '../components/ImageViewerDeviantTracker'
export default class GalleryDeviantTrackerView extends Component {

    constructor(props) {
        super(props);
        this.state = {
          images:this.props.icon.images,
          currentIndex: 0,
        };

    }



    navigate = direction => e => {
	    // param is the argument you passed to the function
	    // e is the event object that returned

	    //console.log(direction)
	    const   images   = this.state.images
	    var currentIndex = this.state.currentIndex + direction
	    
	    
	    if(currentIndex < 0){
	    	currentIndex = images.length -1;
	    }

	    if(currentIndex > (images.length -1)){
	    	currentIndex = 0
	    }

	    this.setState({currentIndex:currentIndex});

	};

    render() {

        const   images   = this.state.images

       
        return (

	            <div className="image-gallery">
	            	<div className="imageContainer">
	            		<ImageViewerDeviantTracker initialWidthPercent="100" image={images[this.state.currentIndex]}/>
	            		
	            		<button className="galleryButton prevBtn" onClick={this.navigate(-1)}></button>
	            		<button className="galleryButton nextBtn" onClick={this.navigate(1)}></button>
	            	</div>
	            	
	            </div>
            )
    }




}
