import base64 from 'base-64';

const KLAVIYO_URL = "https://a.klaviyo.com/api/track?data="

export function sendKlaviyoEvent(episode, userEmail) {

  const game_prefix = "HAK060";
  let data = {
    "token": process.env.REACT_APP_KLAVIYO_TOKEN || 'QLKvDc',
    "event": "episode-complete",
    "customer_properties":{
        "$email":userEmail
    },
    "properties":{
        "episode":game_prefix + episode,
        "source":"web"
    }
  }

  let encodedData = base64.encode(JSON.stringify(data))

  fetch(KLAVIYO_URL + encodedData)
    .then(function (response) {
      //console.log(response)
    })
    .then((data) => {
      //console.log(data)
    })
    .catch(err => console.log('Error with Klaviyo', err));

}