import React, { Component } from 'react'

export default class NoteToSelf extends Component{
  render (){
    return (
    <div className="rtfdoc evidencechecklist">


    	<h2>WHEN YOU NEED TO TAKE A BREAK:</h2>
    	<ul>

    		<li><input type="checkbox" name="" value="1"/> Clean desk</li>
    		<li><input type="checkbox" name="" value="1"/> Have a gin and tonic</li>
    		<li><input type="checkbox" name="" value="1"/> Turn on radio 
    			<ul>
    				<li>- Manic? 105.8FM</li>
    				<li>- Groggy? 97.5FM</li>
    			</ul>
    		</li>
    		<li><input type="checkbox" name="" value="1"/> Have another gin and tonic</li>
    		<li><input type="checkbox" name="" value="1"/> Stretch for 30 min.</li>
    		<li><input type="checkbox" name="" value="1"/> Take a walk/run</li>
    		<li><input type="checkbox" name="" value="1"/> Read something that does not:
    			<ul>
    				<li>- Relate to the case</li>
    				<li>- Require extra brain power</li>
    			</ul>
    		</li>
    		<li><input type="checkbox" name="" value="1"/> Sleep by 1AM (please)</li>

    	</ul>
    </div>
  );
  }
};