import React, { Component } from 'react'

/*import './menuContent.css'*/

class MenuContentDeviantTracker extends Component {
  constructor(props) {
    super(props)

    //console.log(this.props.currentView)
    this.currentEpisode = this.props.currentEpisode

    this.items = [{name:"Home", episode:1},{name:"Audio", episode:5},{name:"Editor", episode:6},{name:"Gallery", episode:2},{name:"Samples", episode:4},{name:"Tools", episode:1},{name:"Videos", episode:3}]
    
  }

  render() {
    return (
      <ul className="menu">
        {this.items.map(i => 
          <li className={(i.name === this.props.currentView ? 'current menu-item ' : 'menu-item ') + (i.episode > this.currentEpisode ? 'menu-item-disabled': ' ') }  key={i.name} 
          onClick={() => { i.episode > this.currentEpisode ? 
   console.log("link not active"): 
   this.props.tapMenu(i.name)  }}  >{i.name}</li>
        )}    
      </ul>
    )
  }
}



export default MenuContentDeviantTracker
