
import React, { Component } from 'react'
const terms =  [
        { name: 'ABRASION', description: 'Superficial damage to the skin.' },
        {
          name: 'ADRENAL',
          description: 'Relating to glands situated above the kidneys.'
        },
        {
          name: 'ANEURYSM',
          description: 'Bulge in artery. Bursting can cause death.'
        },
        { name: 'ANTEMORTEM', description: 'Before death.' },
        {
          name: 'ANTERIOR',
          description: 'Anatomical position meaning in front.'
        },
        { name: 'AORTA', description: 'Largest artery in the body.' },
        { name: 'ATHEROSCLEROSIS', description: 'Plaque buildup in arteries.' },
        { name: 'BASILAR ATELECTASIS', description: 'Partial lung collapse.' },
        { name: 'CEREBRAL', description: 'Relating to the head or brain.' },
        { name: 'CERVICAL', description: 'Relating to the neck or cervix.' },
        { name: 'CONGENITAL', description: 'Trait present from birth.' },
        { name: 'CONJUNCTIVAL', description: 'Relating to the eye.' },
        { name: 'CONTUSION', description: 'Bruise.' },
        { name: 'CUTANEOUS', description: 'Relating to the skin.' },
        {
          name: 'DISTAL',
          description: 'Situated away from the body or point of attachment.'
        },
        {
          name: 'DURA',
          description: 'Membrane around the brain and spinal cord.'
        },
        {
          name: 'EDEMA',
          description: 'Swelling, either from injury or inflammation.'
        },
        {
          name: 'FASCIA',
          description: 'Tissue that separates muscles and organs.'
        },
        {
          name: 'HEMORRHAGE',
          description: 'Escape of blood from a ruptured blood vessel.'
        },
        {
          name: 'HEMOTHORAX',
          description:
            'Collection of blood in the space between the chest wall and the lung.'
        },
        {
          name: 'HERNIA',
          description:
            'Abnormal exit of an organ or tissue or through a weakness in the peritoneum.'
        },
        {
          name: 'INCISION',
          description: 'Clean cut in the skin. Often surgical.'
        },
        { name: 'INFERIOR', description: 'Anatomical position meaning below.' },
        { name: 'INTERCOSTAL', description: 'Situated between the ribs.' },
        { name: 'JUGULAR', description: 'Relating to the throat.' },
        {
          name: 'LARYNX',
          description:
            'Hollow muscular organ forming an air passage to the lungs and holding the vocal cords.'
        },
        {
          name: 'LATERAL',
          description:
            'Situated on one side, especially the side furthest from the middle.'
        },
        {
          name: 'LIVIDITY',
          description:
            'Red or purple discoloration of the skin due to livor mortis.'
        },
        {
          name: 'LIVOR MORTIS',
          description:
            "Settling of blood in the lower or dependent portion of the body after death. Can be used to determine the body's position at the time of death."
        },
        {
          name: 'NORMOCEPHALIC',
          description: 'Normal without any severe abnormalities.'
        },
        { name: 'PARENCHYMA', description: 'Functional tissue of an organ.' },
        {
          name: 'PERFORATION',
          description: 'Hole made by boring or piercing.'
        },
        { name: 'PERIADRENAL', description: 'Surrounding the adrenal glands.' },
        {
          name: 'PERITONEUM',
          description: 'Membrane lining the abdominal cavity.'
        },
        {
          name: 'PETECHIAE',
          description:
            'Red or purple spots on the skin due to broken capillary vessels. Can be a sign of death by asphyxiation if found around the face or eyes.'
        },
        {
          name: 'PLEURA',
          description: 'Pair of membranes enveloping the lungs.'
        },
        {
          name: 'POSTERIOR',
          description: 'Anatomical position meaning in back of or behind.'
        },
        { name: 'POSTMORTEM', description: 'After death.' },
        { name: 'PULMONARY', description: 'Relating to the lungs.' },
        {
          name: 'RETROPERITONEAL',
          description: 'Situated or occurring behind the peritoneum.'
        },
        {
          name: 'RIGOR MORTIS',
          description:
            'Stiffening of the joints and muscles of the body after death.'
        },
        { name: 'SERRATION', description: 'Jagged edge.' },
        {
          name: 'STERNOCLEIDOMASTOID',
          description: 'Largest and most superficial neck muscle.'
        },
        {
          name: 'SUBCUTANEOUS TISSUE',
          description:
            'Innermost layer of skin consisting of loose connective tissue and fat. Contains large blood vessels and nerves.'
        },
        { name: 'SUBGALEAL', description: 'Between the skull and scalp.' },
        { name: 'SUPERFICIAL', description: 'Near the outer surface.' },
        { name: 'SUPERIOR', description: 'Anatomical position meaning above.' },
        {
          name: 'THYROID',
          description: 'Gland in the neck that secretes hormones.'
        },
        { name: 'TRANSECT', description: 'Cut across.' },
        { name: 'ULNAR', description: 'Relating to the largest forearm bone.' }
      ]
export default class AutopsyTermsView extends Component {
   
    render() {

    	const termviews = terms.map((term, index) => {
	        return (
			      <div className="m-8 text-left text-white" key={index}>
			      	<div className="uppercase font-bold">{term.name}</div>
			      	<div className="text-xs mt-2">{term.description}</div>
			      </div>
			    )
		 })



        return (<div className="database-container" > 
        	<h4>Tools</h4>
        	<h2>Autopsy Terms</h2>
            <div className="relative">
                <div className="gallery-viewer__back" onClick={this.props.goBack}>
                    <span className="gallery-viewer__back-arrow">&lt;</span>
                    <span className="gallery-viewer__back-text">GO BACK</span>
                </div>
            </div>
            <div className="database-container-content">
          {termviews}
            </div>

        </div>)
    }




}
