
import React, { Component } from 'react'

export default class Page404 extends Component{
  render (){
    return (
    <div>
<p>Oops! This page is unavailable. Something is broken.<br/><br/>
Please contact the University IT Desk if you continue to have problems.</p>
	</div>
  );
  }
};