import React, { Component } from 'react'
import ImageViewerDeviantTracker from '../components/ImageViewerDeviantTracker'
export default class ImageDeviantTrackerView extends Component {

    constructor(props) {
        super(props);
        this.state = {
          selectedImage: this.props.icon.image,
        };

    }



    render() {

        

        return (<div className="images-container" > 
        	<h4>Tools</h4>
        	<h2>{this.props.icon.name}</h2>


        	
                
              <div className="relative">
                  <div className="gallery-viewer__back" onClick={this.props.goBack}>
                      <span className="gallery-viewer__back-arrow">&lt;</span>
                      <span className="gallery-viewer__back-text">GO BACK</span>
                  </div>

                  <ImageViewerDeviantTracker initialWidthPercent="100" image={this.state.selectedImage} />
              </div>
        	

            

        	</div>)
    }




}
