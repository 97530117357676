import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import '../node_modules/tailwindcss/dist/tailwind.min.css'
import 'react-rangeslider/lib/index.css'
import './css/index.css'
import Desktop from './Desktop'
import Notfound from './Notfound'

const routing = (
	<Router>
	  <Switch>
	      <Route exact path="/" component={Desktop} />
	      <Route component={Notfound} />
	  </Switch>
  	</Router>
)
ReactDOM.render(routing, document.getElementById('root'))