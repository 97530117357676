import React, { Component } from 'react'

export default class CampusLife extends Component{
  render (){
    return (
    <div>
<h3 className="mb-2">Clubs & Organizations</h3>
<p>Want to make some connections on campus but don’t know where to start? From sports teams to debate squads, Whitlock has plenty of places to put like-minded students together. Looking for something a little more unique? We’ve also got our own e-sports and Quidditch teams! <a href="/" className="text-blue underline" onClick={this.props.goto404}>Click here</a> for more info.</p>
<h3 className="mb-2">Volunteering</h3>
<p>Service Learning hours were required in high school, but you probably still want to do some good for the world. Where do you start? <a href="/" className="text-blue underline" onClick={this.props.goto404}>Click here</a> or visit the Community Involvement Center for information on how you can help with local, national, and even international causes.</p>

	</div>
  );
  }
};