import React, { Component } from 'react'
import store from 'store';
import { universityportal } from '../datas/season6/universityportal.json';
import CheeseburgerMenu from 'cheeseburger-menu'
import MenuContentUniversityPortal from '../components/MenuContentUniversityPortal'
import LoginUniversityPortal from '../components/LoginUniversityPortal'
import DesktopIcon from '../components/DesktopIcon'
import ImageViewerDeviantTracker from '../components/ImageViewerDeviantTracker'


import AcademicResources from '../rtf/universityPortal/academicResources';
import NewsAndEvents from '../rtf/universityPortal/newsAndEvents';
import Employment from '../rtf/universityPortal/employment'; 
import CampusLife from '../rtf/universityPortal/campusLife'; 
import ClassSchedule from '../rtf/universityPortal/classSchedule'; 
import Page404 from '../rtf/universityPortal/page404';
const components = {
    "Academic Resources": AcademicResources,
    "News and Events": NewsAndEvents,
    "Employment": Employment,
    "Campus Life": CampusLife,
    "Class Schedule": ClassSchedule,
    "404": Page404

};


export default class UniversityPoralView extends Component {

	constructor(props) {
	    super(props);
	    this.updateDimensions = this.updateDimensions.bind(this)
	    this.currentEpisode = this.props.currentEpisode
	    this.state = {
	      isUnlocked: false,
	      currentView:"Home",
	      menuOpen: false,
	      icons:[],
	      showPwModal: false,
	      selectedIconPw: null,
	      selectedIcon: null,
	      currentUser:0,
	      username:""
	    };
	    //console.log("currentEpisode "+this.currentEpisode)

	    const universitypassword = store.get("universitypassword") ? store.get("universitypassword") : "";
		/*if(universitypassword.toLowerCase() === devianttracker.password.toLowerCase() || !devianttracker.passwordprotected){
			this.state.isUnlocked = true
		}*/

		var i;
		for (i = 0; i < universityportal.users.length; i++) {
			if(universityportal.users[i].password === universitypassword.toLowerCase() ){
				this.state.isUnlocked = true
				this.state.currentUser = i
				this.state.username = universityportal.users[i].username
			}
		}

	}


	openMenu() {
		this.setState({ menuOpen: true })
	}


	logout = e => {
		e.preventDefault();
		this.closeMenu()
		store.set('universitypassword', false);
		this.setState({ currentView: "Home" });
		this.setState({ isUnlocked: false });
		this.setState({ currentUser: 0 });
		this.setState({username: ""})
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		//console.log("tapMenu")
		//console.log(item)
		this.closeMenu()

		if(this.state.currentView !== item){
			//console.log("display view")
			
			if (typeof universityportal[item] !== 'undefined'){

				const icons = universityportal[item].filter(icon => (icon.episode <= this.currentEpisode && icon.user === this.state.currentUser) );

				this.setState({
			        icons: icons
			    }, () => {
			     this.updateDimensions()
			    })

			}else{
				this.setState({ icons: [] })
			}


			
		}
		this.setState({selectedIcon:null})
		if(item === "Request a Transcript" || (item === "Class Schedule" && this.state.currentUser === 1)){
			this.setState({ currentView: "404" })

		}else{
			this.setState({ currentView: item })
		}
		
		
	}

	validateLogin(user){
		this.setState({ isUnlocked: true });
		this.setState({ currentUser: user.index });
		this.setState({username: user.username})
	}


	handleClickIcon = e => {
		//console.log("handleClickIcon")


		var iconIndex =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconIndex)
       let icon = universityportal[this.state.currentView][iconIndex]

      

       this.setState({selectedIcon:icon})


	}


	componentDidMount() {
		this.updateDimensions()
    	window.addEventListener("resize", this.updateDimensions);
  	}


     componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };


    updateDimensions() {

		var newIcons = this.state.icons
	    if (window.innerWidth < 800) {
	        let i = 0
	        let top = 10
	        let left = 0
	        newIcons.forEach(icon => {

	            if (i % 3 === 0) {
	              if (i !== 0) {
	                top += 104
	              }
	              left = 0
	            } else {
	              left += 105
	            }
	            icon.top = top
	            icon.left = left

	            i++
	       
	          //console.log("after "+i)
	        })
	        this.setState({icons:newIcons});
        } else {
	      let i = 0
	      let top = 10
	      let left = 10
	      newIcons.forEach(icon => {
	        
	          if (i % 5 === 0) {
	            if (i !== 0) {
	              top += 120
	            }
	            left = 10
	          } else {
	            left += 105
	          }
	          icon.top = top
	          icon.left = left

	          i++;
	        

	      })
	      this.setState({icons:newIcons});
        } 
	}

	handleTapBackFolderPw = e => {
		//If tapped on the overlay bg

		if ( ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf("login-dt-folder") > -1 ) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf("close-dtfolderlogin") > -1)) {
			e.preventDefault()  
			this.setState({showPwModal:false});
			this.setState({selectedIconPw:null})
		}
     

	 }

	 goto404 = e => {
	 	e.preventDefault();
	 	this.setState({ currentView: "404" })
	 }

	 goBack = e => {
	 	//console.log("BACKKK")
	 	this.setState({selectedIcon:null})
	 }


	openSelectedIconPw(icon){
    	//console.log("openSelectedIconPw")
    	//console.log(icon)

    	this.setState({showPwModal:false});
    	this.setState({selectedIconPw:null})
    	this.setState({selectedIcon:icon})

	}

    render() {

    	var Component = components[this.state.currentView]
    	if(Component == null){
    		Component = Page404
    	}
    	const   icons   = this.state.icons

    	const iconviews = icons.map((icon, index) => {
	        return (
			      <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
			    )
		 })

		 /*const iconviews = icons.map((icon, index) => {
	        if ( icon.episode <= this.currentEpisode){
			    return (
			      <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
			    )
	        }else {return false}
		  })*/


        return (
        	<div className="h-full w-full flex flex-col devianttracker universityportal "> 
        		<div className="header bg-blue">
		        	<div className="flex justify-between items-center ">
		        		<img className="menu-toggle ml-2 menu-icon-dt " alt="menu-toggle" 
	        onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s6/dt-hamburger.svg"/>
	        			<h2>WHITLOCK UNIVERSITY PORTAL</h2>
		        		
		        		<div className="mr-2 "><img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s6/dt-close-button.svg"/></div>
		        		
		        	</div>
		        	<div className="float-right user-infos">
			        		<p className="mr-2 ">Welcome, {this.state.username}!</p>
			        		<a className="mr-2 underline" href="/" onClick={this.logout.bind(this)}>LOG OUT</a>
		        		</div>
		        	</div>
	        	
	        	
	        	<CheeseburgerMenu
	        		className ="menu-universityportal"
	        		topOffset="68px"
			        isOpen={this.state.menuOpen}
			        closeCallback={this.closeMenu.bind(this)}>
			        <MenuContentUniversityPortal currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)} currentEpisode={this.currentEpisode}/>
		      </CheeseburgerMenu>


	        	<div className="deviant-container flex-1" >
	        		{this.state.currentView === "Home" && (
	        			<div>
	        				<h2>Welcome to the Whitlock University Portal.</h2>
	        				<p>This is your hub for all campus resources as well as managing and sharing your own files for classes.<br/><br/>Links and resources can be viewed in the menu on the top left.</p>
	        			</div>
	        		)}
	        		
	        		
	        		{(this.state.currentView !== "Home" && this.state.currentView !== "Documents and Files" && this.state.selectedIcon === null) && (<div><h2>{this.state.currentView}</h2><Component goto404={this.goto404.bind(this)}/></div>)}
	        		{(this.state.currentView !== "Home" && this.state.currentView === "Documents and Files" && this.state.selectedIcon === null) && (<div><h2>{this.state.currentView}</h2>{iconviews}</div>)}

	        	
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "image") && (
	        		
	        		  <div className="images-container relative">
	        		  	<div className="headerImageViewUniveristy mb-4">
	        		  		<h3>{this.state.selectedIcon.name}</h3>

	        		  		<div className="gallery-viewer__back" onClick={this.goBack}>
		                        <span className="gallery-viewer__back-arrow">&lt;</span>
		                        <span className="gallery-viewer__back-text">GO BACK</span>
		                    </div>

	        		  	</div>
		        		
	        			<ImageViewerDeviantTracker initialWidthPercent="100" image={this.state.selectedIcon.image} />
	        		</div>)}
	        		

	        	</div>

	        	{!this.state.isUnlocked && (
                <LoginUniversityPortal tapBack={this.props.tapBack.bind(this)} validateLogin={this.validateLogin.bind(this)}/>
              	)}
        	

        	</div>)
    }
}
