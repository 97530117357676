import React, { Component } from 'react'

/*import './menuContent.css'*/


/*
<ul className="menu">
        {this.items.map(i => 
          <li className={(i.name === this.props.currentView ? 'current menu-item ' : 'menu-item ') + (i.episode > this.currentEpisode ? 'menu-item-disabled': ' ') }  key={i.name} 
          onClick={() => { i.episode > this.currentEpisode ? 
   console.log("link not active"): 
   this.props.tapMenu(i.name)  }}  >{i.name}</li>
        )}    
      </ul>

      */


class MenuContentUniversityPortal extends Component {


  render() {
    return (

      <div className="ml-2 pt-2">
        <h3 className="font-bold mb-2 mt-4">University Links</h3>
        <ul className="menu">
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Academic Resources")  }}>Academic Resources</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("News and Events")  }}>News and Events</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Employment")  }}>Employment</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Campus Life")  }}>Campus Life</li>
        </ul>

        <h3 className="font-bold mb-2 mt-4">Profile Links</h3>
        <ul className="menu">
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Documents and Files")  }}>Documents and Files</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Class Schedule")  }}>Class Schedule</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Request a Transcript")  }}>Request a Transcript</li>
          <li className="menu-item text-blue underline mb-2" onClick={() => {this.props.tapMenu("Home")  }}>Home</li>
        </ul>



      </div>


      
    )
  }
}



export default MenuContentUniversityPortal
