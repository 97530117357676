import React, { Component } from 'react'

export default class Assignment extends Component{







  render (){



  	if(this.props.currentEpisode === 6){
		return (<div className="rtfdoc planofaction">



<p>To determine the killer, we need to prove they had the motive, means, and opportunity to commit the murder. When evaluating the remaining suspects, consider these three criteria:</p>
<ul><li><b>- Motive:</b> Did the suspect have a reason to commit the murder? Can their motive be proven?</li>
<li><b>-- Means:</b> Did the suspect have the ability to commit the murder? Did they have access to the murder weapon?</li>
<li><b>-- Opportunity:</b> Did the suspect have the chance to commit the murder? Were they in the vicinity of the crime scene, or do their whereabouts remain unaccounted for?  
If the answer to one or more of these questions is "yes," be sure to examine your evidence to support your claim.</li></ul>
<p>For this assignment, it's time to identify the killer. Send me a message through the Contact shortcut. Title the message with the full name of the suspect you believe committed the crime. If you think you can't identify the murderer, title the message 'Nothing found' and, per usual, I'll try to help out.<br/>It's time to put your investigative skills to the test so we can bring Toby's killer to justice! </p>



			</div>)
	}else{
		 return (
    <div className="rtfdoc planofaction">
		<p>To eliminate a suspect from our list, we need to prove they lacked the motive, means, and/or opportunity to commit the murder. When evaluating each suspect, ask yourself these questions:</p>
<ul><li><b>Motive:</b>Did the suspect lack a motive or reason to commit the murder? Can their lack of motive be proven?</li>
<li><b>Means:</b>Did the suspect lack the means or ability to commit the murder? Did they not have access to the murder weapon?</li>
<li><b>Opportunity:</b>Did the suspect lack the opportunity to commit the murder? Were they somewhere else during the time of the murder?</li></ul>
<p>If the answer to one or more of these questions is “yes” and there’s definitive evidence to support your claim, then the suspect can be eliminated.<br/><br/>
For this assignment, I’d like you to eliminate one suspect from our list. Send me a message through the Contact shortcut and title the message with the full name of the suspect you’re eliminating. If you think you can’t rule someone out, title the message ‘Nothing found’ and per usual, I’ll try to help out.
<br/><br/>You might feel the need to keep everyone in the running—the nature of a good investigator is to always be suspicious. But if we can’t narrow our focus, we won’t be able to pinpoint the killer. Trust me!</p>
    </div>
  );
	}



   
  }
};

