import React, { Component } from 'react'
import Slider from 'react-rangeslider'
import LoginDeviantTrackerFolder from '../components/LoginDeviantTrackerFolder'
import { dteditor } from '../datas/season6/editor.json';
export default class EditorDeviantTrackerView extends Component {

	constructor(props) {
	    super(props);
	   
      this.state = {
        filename: false,
        password: false,
        currentStep:0,
        initialWidthPercent:100,
        images:[]
      };


	    //console.log(dteditor)
	}

  
  goBack(){
    this.setState({ filename: false });
    this.setState({ password: false });
    this.setState({ currentStep: 0 });
    this.setState({ images: [] });
  }
	

  zoomOut = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.max(widthPercent - 20, 100); 
     this.setState({initialWidthPercent:widthPercent});
  };


  zoomIn = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.min(widthPercent + 20, 500); 
     this.setState({initialWidthPercent:widthPercent});
  };


  openEditor(input){    const currentStep = this.state.currentStep
    if(currentStep === 0){
      this.setState({ filename: input });
    }else{
      this.setState({ password: input });


      let arrayImageName = this.state.filename.replace(".jpg","")
      //console.log(arrayImageName)
      this.setState({ images: dteditor[arrayImageName] });
    }
    this.setState({ currentStep: currentStep + 1 });
  }


  handleChangeOpacity = (value, index) => {
      //console.log("handleChangeOpacity")
      //console.log(value)
      //console.log(index)

      var images = this.state.images;

      //console.log(images)

      images[index].opacity = value / 100

      //console.log(images)

      this.setState({ images: images });
    };



    render() {
      
      const contentFileStyle = {
        width:this.state.initialWidthPercent + "%"
      };

      var images = this.state.images
      //console.log(images)

        return (
          <div className="editor-container relative" > 
            <h2>Editor</h2>

           {(this.state.currentStep >= 2) && (
                <div className="relative">
                    <div className="gallery-viewer__back" onClick={this.goBack.bind(this)}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>
                </div>
            )}


                
                
              


                {(this.state.currentStep < 2) && (<LoginDeviantTrackerFolder currentStep={this.state.currentStep} filenames={dteditor.filenames} password={dteditor.password} openEditor={this.openEditor.bind(this)}/> )}
              
               

                {(this.state.currentStep >= 2) && (

                    <div className="imageViewer">
                      <div className="bottom-imageViewer-controllers">
                      <button onClick={this.zoomIn}>Zoom In</button>
                      <button onClick={this.zoomOut}>Zoom Out</button>
                      
                    </div>
                      <div className="content-file" style={contentFileStyle}>


                       {(this.state.filename === "img_tm2788.jpg") && (<img src="https://cdn.huntakiller.com/huntakiller/s6/img-tm27886.png" alt="img-tm27886"  style={{ opacity: 1  }}/> )}
                       {(this.state.filename === "img_tm2789.jpg") && (<img src="https://cdn.huntakiller.com/huntakiller/s6/img-tm27896.png" alt="img-tm27886"  style={{ opacity: 1  }}/> )}


                        {images.map(function(image, index){
                          return <img src={"https://cdn.huntakiller.com/huntakiller/s6"+image.image} alt={"editor "+index} key={image.image} style={{ opacity: Math.abs(image.opacity)  }}/>
                        })}

                        
                      </div>
                    
                      <div className="sliders">

          

                      {(this.state.filename === "img_tm2788.jpg") && (
                      <>
                          <div className='slider mb-10' >
                                      <Slider
                                        min={0}
                                        max={100}
                                        tooltip={false}
                                        value={images[0].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 0)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10'>
                                      <Slider
                                        min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[1].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 1)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10' >
                                      <Slider
                                        min={0}
                                        max={100}
                                        tooltip={false}
                                        value={images[2].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 2)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10' >
                                      <Slider
                                        min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[3].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 3)}
                                        
                                      />
                                    </div>


                          <div className='slider mb-10' >
                                      <Slider
                                        min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[4].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 4)}
                                        
                                      />
                                    </div>
                                  </>
                      )}



                      {(this.state.filename === "img_tm2789.jpg") && (
                      <>
                          <div className='slider mb-10' >
                                      <Slider
                                        min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[0].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 0)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10'>
                                      <Slider
                                        min={0}
                                        max={100}
                                        tooltip={false}
                                        value={images[1].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 1)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10' >
                                      <Slider
                                         min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[2].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 2)}
                                        
                                      />
                                    </div>

                          <div className='slider mb-10' >
                                      <Slider
                                         min={-100}
                                        max={0}
                                        tooltip={false}
                                        value={images[3].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 3)}
                                        
                                      />
                                    </div>


                          <div className='slider mb-10' >
                                      <Slider
                                        min={0}
                                        max={100}
                                        tooltip={false}
                                        value={images[4].opacity * 100}
                                        onChange={(e) => this.handleChangeOpacity(e, 4)}
                                        
                                      />
                                    </div>
                                  </>
                      )}
                        





                      </div>
                    
                  </div>





                )}

                


              

        	</div>)
    }




}
