import React, { Component } from 'react'

export default class VideoDeviantTrackerView extends Component {


  componentWillMount () {
    const script = document.createElement("script");
 
    script.src = "https://fast.wistia.com/embed/medias/"+this.props.icon.video+".jsonp";
    script.async = true;
 
    document.body.appendChild(script);

    const script2 = document.createElement("script");
 
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
 
    document.body.appendChild(script2);
  }

	

    render() {
    	   
         const divStyle = {
          
          position: "relative"
        };

        const divStyle2 = {
          height: "100%",
          margin:"0 auto",
          width:"950px",
          maxWidth:"100%"
        };

        const divStyle3 = {
          height: "100%",
          width:"100%",
          position: "relative"
        };

        const divStyle4 = {
          height:"100%",
          left:"0",
          opacity:"1",
          overflow:"hidden",
          position:"absolute",
          top:"0",
          transition:"opacity 200ms",
          width:"100%"
        };

        const imgStyle = {
          filter:"blur(5px)",
          height:"100%",
          objectFit:"contain",
          width:"100%"
        };
    	

        return (<div className="video-container" > 
        	<h4>Videos</h4>
          <h2>{this.props.icon.name}</h2>
          <div className="relative">
                    <div className="gallery-viewer__back" onClick={this.props.goBack}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>
                </div>
                
              
                  <div className="wistia_responsive_padding" style={divStyle}>
                   <div className="wistia_responsive_wrapper" style={divStyle2}>
                     <div className={'wistia_embed videoFoam=true wistia_async_' + this.props.icon.video} style={divStyle3}>
                       <div className="wistia_swatch" style={divStyle4}>
                        <img src={"https://fast.wistia.com/embed/medias/"+this.props.icon.video+"/swatch"} style={imgStyle} alt="" aria-hidden="true" />
                       </div>
                     </div>
                   </div>
                 </div>

                 <div className=" videotranscriptcontainer">
                  <a  href={"https://cdn.huntakiller.com/huntakiller/s6"+this.props.icon.transcript} target="_blank" className="download-transcript-btn" rel="noopener noreferrer" download={this.props.icon.transcript}>Transcript</a>

                 </div>
                 
                    


              
               


              

        	</div>)
    }




}
