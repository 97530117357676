import React, { Component } from 'react'

export default class Employment extends Component{
  render (){
    return (
    <div>

<h3 className="mb-2">Career Planning</h3>
<p>Applying for a job? Getting ready to graduate? The Career Center can help you with that! We provide student tutors to look over resumes and cover letters, as well as consult with people already in the job sphere to give our students a leg up in the outside world.</p>
<h3 className="mb-2">On-Campus Part Time Jobs</h3>
<p>Are you looking for a job, but transportation is an issue? Want a job with flexible hours, one that will be aware of your classes and projects, and give you time to complete them? Visit the Career Center, where you can find a list of every available on-campus job.</p>
<h3 className="mb-2">Job Portal</h3>
<p><a href="/" className="text-blue underline" onClick={this.props.goto404}>Click here</a> for a list of on-campus and off-campus job offerings, as well as information regarding Whitlock’s various connections to jobs in the community.</p>	

	</div>
  );
  }
};