import React, { Component } from 'react'
import Draggable from 'react-draggable';


export default class DesktopIcon extends Component {
    
    onStop = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y
    };

    handleDrag = (e, position) => {
        const {x, y} = position;
        this.props.icon.left = x
        this.props.icon.top = y

    };

   
    render() {
    
        const dragHandlers = {onStop: this.onStop, onDrag:this.handleDrag};

    	const icon = this.props.icon
        const controlledPosition = {x: icon.left, y: icon.top};
    	


        
        return (
            <Draggable position={controlledPosition} {...dragHandlers} disabled={true}>
    			<div className="icon-wrapper" id={`icon-${icon.id}`} onClick={this.props.iconSingleClick}>
    				<img src={"https://cdn.huntakiller.com/huntakiller/s6/"+icon.icon}  className=" icon-image" alt={icon.name} />
    				
    			</div>
            </Draggable>
        )
    }
}
